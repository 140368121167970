body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myFooterBox{
    background-color: #7b8188;
    width: 100%;
    bottom: 0;
    color: white;
    height: 50px;
    position: relative;
    padding-top: 15px;
    text-align: center;
    font-weight: bold;
    opacity: 0.6;
    margin-top: 3%;
}


.headerLine{
    height: 2px;
    border-radius: 1px;
    margin-left: 20px;
    right: 20px;
    width: 90vh;
}
.myLinkHeader{
    pointer-events: all;
    color: currentColor;
    /* text-decoration:  #00aced;*/
    -webkit-text-decoration:  #AED6F1;
            text-decoration:  #AED6F1;
    cursor: pointer;
}
.pageHeaderModuleLinkBox{
    float: right;
    font-size: 18px;
    font-weight: bold;
    /*background-color: red;*/
    color:white;
    margin-top: 10px;
    margin-right: 10px;

}
.pageHeaderModuleLinkBox a{
    margin: 3px;
    z-index: 100;
}
.logoCompany{
    height: 50px;
    width: 50px;
    background-color: white;
    border-bottom-width: 1;
    border-bottom-color: black;
    border-radius: 25px;
    color: blue;

}
.companyTitle{
    font-size: 24px;
    margin-left: 10px;
    vertical-align: center;
}
.pageHeaderModule{
    height: 60px;
    background-color: #1F4E79;/*#808080; #13B5EA;  #7b8188;*/
    color: white;
    vertical-align: center;
}

.pageHeaderMenu{
    height: 50px;
    background-color: #1F4E79;/*#808080; #13B5EA;*/
    color:white;
    padding-left: 60px;
    padding-top: 10px;
}

.pageHeaderMenu button{
    margin-left: 5px;
    height: 15px;
}
.pageHeaderMenuButton{
    margin-left: 5px;
    font-weight: bold;
    border-right-color: white;
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 10px;
}
.pageInfoSelected{
    padding-top: 5px;
}


.pageHeader{
    margin-top: -0px;
    height: 100px;
    background-color: white;
    color:black;
}


body{
    margin: 0;
}


.wallpaper {
    background-image: url(/static/media/background.bd9e1c83.jpg);

    /* Full height */
    height: auto;
    min-height: 100vh;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top:0;
    bottom:0;
    overflow: auto;
}

.overlay{
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top:0;
    bottom:0;
    height: 100%;
    width: 100%;
    z-index: -5;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.myTitleLogin{
    color: #7b8188;
    font-size: 16px;
    font-family: "cursive";
    padding-bottom: 10px;
    text-underline: black;
    border-bottom-width: 1px;
    border-bottom-color: white;
    border-bottom-style: solid;

}
.loginContentBox{
   /* height: 53%;*/
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
}

.registerContentBox{
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
    overflow: scroll;
    max-height: 80%;
}



.centerMyDiv{
    margin: 0 auto 0;
}


table.myTableBorder {
    border-spacing: 1em .5em;
    padding: 0 2em 1em 0;
    border: 1px solid orange;
}

/*table  td{
    width: 1.5em;
    height: 1.5em;
    background: #d2d2d2;
    text-align: center;
    vertical-align: middle;
}
table, td, th {
    border: 1px solid black;
}*/

.clickMe{
    cursor: pointer;
}

.pageContentBox{

   /* width: 100%;
    min-height: 75%;*/
    width: 100%;
    min-height: 100%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 2%;
}

.headerLogout{
    color: lightcoral;
    text-decoration: underline;

}

.headerMenuSelectSecondBar{
    color: yellow;
    font-weight: bold;
    font-size: 16px;
}
.borderRight{
    border-right-width: 1;
    border-right-color: black;
    border-right-style: solid;
}
.card-header{
    background-color: gray;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
}

.stepwizard-step p {
    margin-top: 10px;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 50%;
    position: relative;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-order: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
btn-triangle{

}

.btn-arrow-down {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #00b3ee;
}

.form-inline {
    margin: 20px 0;
}
.form-inline .form-group {
    margin-bottom: 0;
}
.form-inline .form-group,
.form-inline .form-control,
.form-inline button {
    width: 100%;
}
.bg-danger,
.bg-info {
    padding: 15px;
    margin-bottom: 20px;
}
.map {
    height: 300px;
}
