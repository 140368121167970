.form-inline {
    margin: 20px 0;
}
.form-inline .form-group {
    margin-bottom: 0;
}
.form-inline .form-group,
.form-inline .form-control,
.form-inline button {
    width: 100%;
}
.bg-danger,
.bg-info {
    padding: 15px;
    margin-bottom: 20px;
}
.map {
    height: 300px;
}